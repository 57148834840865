import React, { Component } from 'react'

class Footer extends Component {

    render() {
        return (
            <>
                <footer>
                    <div className="container margin_60_35">
                        <div className="row">
                            <div className="col-lg-5 col-md-12 pe-5">
                                <p><img src="assets/img/biglogo.png" width="100" height="50" alt="" /></p>
                                <p>
                                    We are a funug an event listing and ticketing platform.
                                </p>
                                <div className="follow_us">
                                    <ul>
                                        <li>Follow us</li>
                                        <li><a href="#0"><i className="ti-facebook"></i></a></li>
                                        <li><a href="#0"><i className="ti-twitter-alt"></i></a></li>
                                        <li><a href="#0"><i className="ti-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 ms-lg-auto">
                                <h5>Useful links</h5>
                                <ul className="links">
                                    <li><a href="/about-funug">About</a></li>
                                    <li><a href="#">Login</a></li>
                                    <li><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></li>
                                    {/* <li><a href="blog.html">News &amp; Events</a></li> */}
                                    {/* <li><a href="contacts.html">Contacts</a></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <h5>Contact with Us</h5>
                                <ul className="contacts">
                                    <li><a href="tel://+256394510524"><i className="ti-mobile"></i> + 256 39 300 0417</a></li>
                                    <li><a href="mailto: tickets@funug.co"><i className="ti-email"></i>  tickets@funug.co</a></li>
                                </ul>

                            </div>
                        </div>
                        <hr />

                    </div>
                </footer>

            </>
        )
    }
}
export default Footer