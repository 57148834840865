const ACCESS_TOKEN = localStorage.getItem('funug@user');
const apiHost = 'https://funugapi.qubitsanalytic.com/funug-api/'
// const apiHost = 'http://localhost:9554/funug-api/'

export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password,
        }
        //console.log("llllllllllllllllll",data)
        try {
            let response = await fetch(apiHost + 'user/login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();

            //console.log("llllllllllllllllll",result)

            return result;
        } catch (error) {
            console.log(error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            // console.log("mmmmmmmmmmmmmmmmmmm",error)
            return connError;
        }

    },

    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/profile',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async updateUser(
        userId,
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        profilePhoto,
        roleId,
        updatedBy
    ) {

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "phone_number": phoneNumber,
            "profile_photo": profilePhoto,
            "role_id": roleId,
            "updated_by": updatedBy,
            "user_id": userId
        }
        try {
            let response = await fetch(apiHost + 'user.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listUpcomingEvent() {

        try {
            let response = await fetch(apiHost + 'user/role.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getLiveEvents(user_id,event_status,flag)
    {

        let data = {
            "user_id": user_id,
            "tour_status":event_status,
            "tour_flag":flag
        }

        try {
            let response = await fetch(apiHost + 'tour.list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getAllFun(page,user_id)
    {

        let data = {
            "page":page,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'tour.list.all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getEventInfo(event) 
    {

        let data = {
            "tour_id": event
        }

        try {
            let response = await fetch(apiHost + 'tour.info',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getMostClicked(id) 
    {

        let data = {
            "tour_id": id
        }

        try {
            let response = await fetch(apiHost + 'tour.most.clicked',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async regsisterClick(id) 
    {

        let data = {
            "tour_id": id
        }

        try {
            let response = await fetch(apiHost + 'make.click',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createTicket(pay_phone,holder_details,total,event) 
    {

        let data = {
            'pay_phone':pay_phone, 
            'holder_details':holder_details,
            'total':total,
            'event':event
        }

        try {
            let response = await fetch(apiHost + 'create.ticket',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });


            let result = await response.json();

            return result;
        } catch (error) {
            console.log("kalos eee ",error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

}