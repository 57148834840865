import React, { Component, useEffect, useState, CSSProperties } from 'react'
import functions from '../utils/functions'
import { useHistory } from 'react-router-dom';
import $ from 'jquery'
import { SpinnerCircularSplit } from 'spinners-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Select } from 'antd'
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const BuyTicket = (props) => {

    const fees = 1000
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [ticketQuantity, setTicketQuantity] = useState(0)
    const [tickeTotal, setTicketTotal] = useState(0)
    const [handlingFee, setHandlingFee] = useState(0)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [fullnamed, setFullnamed] = useState("")
    const [phonenumbered, setPhonenumbered] = useState("")
    const [emailed, setEmailed] = useState("")
    const [paymentPhone, setPaymentPhone] = useState("")
    const [cart, setCart] = useState([])
    const [subTotal, setSubTotal] = useState(0)
    const [priceId, setPriceId] = useState("")

    const nav = useHistory();

    const addToCart = () => {
        if (props.ticketTypes.length > 0) {
            setCart(props.ticketTypes)
            //console.log("kakakakakakak")
        }
    }

    const handleTicketIncrease = (idd) => {

        const yup = cart.map((item) =>
            idd === item.priceId ? { ...item, quantity: parseInt(item.quantity) + (parseInt(item.quantity) < 10 ? 1 : 0) } : item

        )
        setCart(yup)
        setPriceId(idd)
        // handleSubTotal(idd)

    };

    const handleTicketDecrease = (idd) => {
        const yup = cart.map((item) =>
            idd === item.priceId ? { ...item, quantity: parseInt(item.quantity) - (parseInt(item.quantity) > 0 ? 1 : 0) } : item
        )
        setCart(yup)
        //handleSubTotal(idd)
    }

    const handleSubTotal = (idd) => { //handlingFee
        const sup = cart.map((item) =>
            idd === item.priceId ? ({ ...item, subtotal: parseInt(item.amount) * (item.quantity) }) : item
        )
        //console.log(sup)

        setCart(sup)
    }

    const handling = () => {
        let handle = 0
        cart.map((item) => 
            item.amount === 0 ? (handle = parseInt(0)): handle += (parseInt(fees) * item.quantity),
        )
        setHandlingFee(handle)
    }

    const notify = (msg) => toast(msg);

    const closeMe = () => {

        console.log(props.ticketTypes)

        setTicketQuantity(0)
        setTicketTotal(0)
        setHandlingFee(0)
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
        setProcessing(false)
        setFullnamed("")
        setPhonenumbered("")
        setEmailed("")
        setPaymentPhone("")
        // setCart([])

    }

    const doStepOne = () => {
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
    }

    const doStepTwo = () => {
        if (ticketQuantity > 0) {
            setStepTwo(true)
            setStepOne(false)
            setStepThree(false)
            console.log(cart)
        } else {
            notify("You must at least buy one ticket")
        }

    }

    const doStepThree = () => {
        if (emailed > 0 || fullnamed > 0 || phonenumbered > 0) {
            setStepThree(true)
            setStepTwo(false)
            setStepOne(false)
        } else {
            notify("All ticket holder details are required")
        }

    }

    const handleProcessPayment = () => {
        if (paymentPhone != "") {
            setProcessing(true)
            setTimeout(
                () => {
                    setProcessing(false);

                    setStepTwo(true)
                    setStepOne(false)
                    setStepThree(false)

                    // nav.push({
                    //     pathname: '/your/ticket',
                    //     search: '?q=1',
                    //     state: { tourName: props.tourName, tourDate: props.when, tourPoster: props.image }
                    // })
                },
                3000
            );
        } else {
            notify("Please Enter Mobile Money Phone Number")
        }
    }

    const paymentPhon = (e) => {
        if (e.target.value != "") {
            setPaymentPhone(e.target.value)
        } else {
            setPaymentPhone('')
        }
    }

    const fullname = (e) => {
        if (e.target.value != "") {
            setFullnamed(e.target.value)
        } else {
            setFullnamed('')
        }
    }

    const phoneNumber = (e) => {
        if (e.target.value != "") {
            setPhonenumbered(e.target.value)
        } else {
            setPhonenumbered('')
        }
    }

    const emailAddress = (e) => {
        if (e.target.value != "") {
            setEmailed(e.target.value)
        } else {
            setEmailed('')
        }
    }

    const handlePrice = () => {
        let total = 0
        cart.map((item) => 

            total += ((parseInt(item.amount) * item.quantity)),
        
        )
        setTicketTotal(total+ handlingFee)
        setSubTotal(total)
    }

    const handleQuantity = () => {
        let qty = 0
        cart.map((item) => 
            qty += parseInt(item.quantity),
        )
        setTicketQuantity(qty)
    }

    useEffect(() => {
        if (props.ticketTypes) {
            addToCart()
            handlePrice()
            handleQuantity()
            handling()
        }
        if (priceId != "") {
            handleSubTotal(priceId)
        }
    }, [props.ticketTypes,cart])

    return (

      <Modal
        title={"Available ticket type(s) / categories"}
        centered
        bodyStyle={{ paddingBottom: '0px', paddingTop: '0px' }}
        visible={props.visible.visible}
        width={1000}
        maskClosable={false}
        footer={[null]}
        maskTransitionName=""
        // onCancel={this.handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: false }}
      >

        <br />


        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h3 style={{ marginLeft: '10px' }} class="modal-title fs-5" id="exampleModalToggleLabel">Available ticket type(s) / categories</h3> */}
              {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div class="modal-body">

              <div class="modal-content">
                {/* <div class="modal-header">
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                  <h3 style={{ marginLeft: '10px' }} class="modal-title fs-5" id="exampleModalToggleLabel">Available ticket type(s) / categories
                  </h3>
                  {this.state.processing ? (
                    null
                  ) : (
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  )}

                </div> */}
                <div class="modal-body">

                  <div class="container">
                    <div class="row">


                      {stepOne ? (
                        <div class="col-xl-4" id='smallphone'>
                          <div class="mt-5 mt-lg-0">
                            <div class="card border shadow-none">
                              <div class="card-header bg-transparent border-bottom py-3 px-4">
                                <h5 class="font-size-16 mb-0"><strong> {props.tourName}</strong><span class="float-end"></span></h5>
                              </div>
                              <div class="card-body p-4 pt-2">

                                <div class="table-responsive">
                                  <table class="table mb-0">
                                    <tbody>
                                      <tr class="bg-light">
                                        <th>Grand Total:</th>
                                        <td class="text-end">
                                          <span class="fw-bold">
                                            UGX {tickeTotal}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}


                      <div class="col-xl-8">


                        {stepOne ? (
                          <>

                            {Array.isArray(cart) ?
                              (
                                cart.map((item, key) =>

                                  <div class="card border shadow-none" key={key}> {/*onChange={() => addToCart(item)}*/}
                                    <div class="card-body">

                                      <div class="d-flex align-items-start border-bottom pb-3" >
                                        <div class="me-4">
                                          <img src={props.image} alt="" class="avatar-lg rounded" />
                                        </div>
                                        <div class="flex-grow-1 align-self-center overflow-hidden">
                                          <div>
                                            <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">{item.category} </a></h5>
                                            <p class="text-muted mb-0">
                                              <i class="bx bxs-star text-warning"></i>
                                              <i class="bx bxs-star text-warning"></i>
                                              <i class="bx bxs-star text-warning"></i>
                                            </p>
                                            <p class="mb-0 mt-1">Sales Close  <span class="fw-medium">{props.close}</span></p>
                                          </div>
                                        </div>
                                        <div class="flex-shrink-0 ms-2">
                                          <ul class="list-inline mb-0 font-size-16">
                                            <li class="list-inline-item">
                                              <a href="#" class="text-muted px-1">
                                                <i class="mdi mdi-trash-can-outline"></i>
                                              </a>
                                            </li>
                                            <li class="list-inline-item">
                                              <a href="#" class="text-muted px-1">
                                                <i class="mdi mdi-heart-outline"></i>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div>
                                        <div class="row">
                                          <div class="col-md-4">
                                            <div class="mt-3">
                                              <p class="text-muted mb-2">Price</p>
                                              <h5 class="mb-0 mt-2"><span class="font-size-16 fw-normal"><strong>UGX {item.amount}</strong></span></h5>
                                            </div>
                                          </div>
                                          <div class="col-md-5">
                                            <div class="mt-3">
                                              <p class="text-muted mb-2">Quantity</p>
                                              <div class="d-inline-flex">
                                                <div class="input-group">
                                                  <input style={{ color: 'black' }} type="button" value="-" class="btn btn-success" data-field="quantity" onClick={() => handleTicketDecrease(item.priceId)} />
                                                  <input style={{ color: 'black' }} type="button" value={item.quantity} class="btn btn-success" />
                                                  <input style={{ color: 'black' }} type="button" value="+" class="btn btn-success" onClick={() => handleTicketIncrease(item.priceId)} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="mt-3">
                                              <p class="text-muted mb-2">Total</p>
                                              <h5>UGX {/*{isNaN(tickeTotal) ? 0 : tickeTotal}{(item.quantity) * (item.amount)}*/} {item.subtotal}</h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>

                                )
                              ) :
                              (
                                null
                              )

                            }

                          </>
                        ) : null}


                        {stepTwo ? (
                          <div class="card border shadow-none">
                            <div class="card-body">

                              <div class="d-flex align-items-start border-bottom pb-3">
                                <div class="flex-grow-1 align-self-center overflow-hidden">
                                  <div>
                                    <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">Ticket Holder </a></h5>
                                  </div>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                  <ul class="list-inline mb-0 font-size-16">
                                    <li class="list-inline-item">
                                      <a href="#" class="text-muted px-1">
                                        <i class="mdi mdi-trash-can-outline"></i>
                                      </a>
                                    </li>
                                    <li class="list-inline-item">
                                      <a href="#" class="text-muted px-1">
                                        <i class="mdi mdi-heart-outline"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div>
                                <div class="row">
                                  <div class="col-md-4">
                                    <div class="mt-3">
                                      <p class="text-muted mb-2">Fullname</p>
                                      <input type='text' onChange={fullname} value={fullnamed} class="form-control" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="mt-3">
                                      <p class="text-muted mb-2">Phone number</p>
                                      <input onChange={phoneNumber} value={phonenumbered} class="form-control" type="text" />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="mt-3">
                                      <p class="text-muted mb-2">Email Address</p>
                                      <input onChange={emailAddress} value={emailed} class="form-control" type="text" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                        ) : null}

                        {stepThree ? (
                          <div class="card border shadow-none">
                            <div class="card-body">

                              <div class="d-flex align-items-start border-bottom pb-3">
                                <div class="flex-grow-1 align-self-center overflow-hidden">
                                  <div>
                                    <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">
                                      {processing ? (
                                        <>
                                          <strong>
                                            Processing . . . <br />
                                          </strong>
                                          Please enter pin to complete

                                        </>
                                      ) : (
                                        <>
                                          Payment Details
                                        </>
                                      )}

                                    </a>
                                    </h5>
                                  </div>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                  <ul class="list-inline mb-0 font-size-16">
                                    <li class="list-inline-item">
                                      <a href="#" class="text-muted px-1">
                                        <i class="mdi mdi-trash-can-outline"></i>
                                      </a>
                                    </li>
                                    <li class="list-inline-item">
                                      <a href="#" class="text-muted px-1">
                                        <i class="mdi mdi-heart-outline"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div>
                                <div class="row">
                                  <div class="col-md-4">
                                  </div>
                                  <div class="col-md-4">
                                    <div class="col-md-4 col-lg-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                      <img class="btn mt-2 mr-2" src="../assets/img/airtel.webp"
                                        style={{ height: '60px', width: '100%', border: 'solid', borderColor: 'red', marginRight: '10px', marginTop: '20px' }} alt="Airtel Mm Logo" />
                                      <img class="btn mt-2 mr-2" src="../assets/img/mtn.png"
                                        style={{ height: '60px', width: '100%', border: 'solid', borderColor: 'yellow' }} alt="Mtn Mm Logo" />
                                    </div>
                                    <div class="mt-3">
                                      <p class="text-muted mb-2">Payment Phone:</p>
                                      <input onChange={paymentPhon} value={paymentPhone} class="form-control" type="text" readOnly={processing} />
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                        ) : null}

                        {/* <!-- end card --> */}

                        <div class="row my-4">
                          <div class="col-sm-6">

                            {processing ? (
                              null
                            ) : (
                              <a class="btn btn-warning" onClick={closeMe} data-bs-dismiss="modal">
                                <i class="mdi mdi-cart-outline me-1"></i> close </a>
                            )}
                          </div>
                          {/* <!-- end col --> */}
                          <div class="col-sm-6">
                            <div class="text-sm-end mt-2 mt-sm-0">

                              {stepOne ? (
                                <a onClick={doStepTwo} class="btn btn-success">
                                  Proceed </a>
                              ) : (<>
                                {stepThree ? (<>
                                  {processing ? (
                                    <button class="btn btn-success" >
                                      <SpinnerCircularSplit thickness={120} enabled={processing} color='#FFFFFF' height='30' />
                                    </button>
                                  ) : (
                                    <button id='processPay' class="btn btn-success" onClick={handleProcessPayment}>
                                      Process Payment </button>
                                  )}




                                </>
                                ) : (<>
                                  <a onClick={doStepOne} class="btn btn-danger">
                                    Back to start </a>

                                  <a onClick={doStepThree} class="btn btn-success" style={{ marginLeft: '15px' }}>
                                    Proceed </a>
                                </>
                                )}
                              </>
                              )}

                              {/* <a onClick={doStepTwo} class="btn btn-success">
                        <i class="mdi mdi-cart-outline me-1"></i> Proceed </a> */}
                            </div>
                          </div>
                          {/* <!-- end col --> */}
                        </div>
                        {/* <!-- end row--> */}
                      </div>

                      <div class="col-xl-4" id='smallphonetwo'>
                        <div class="mt-5 mt-lg-0">
                          <div class="card border shadow-none">
                            <div class="card-header bg-transparent border-bottom py-3 px-4">
                              <h5 class="font-size-16 mb-0"><strong> {props.tourName}</strong><span class="float-end"></span></h5>
                            </div>
                            <div class="card-body p-4 pt-2">

                              <div class="table-responsive">
                                <table class="table mb-0">
                                  <tbody>
                                    <tr>
                                      <td>Sub Total :</td>
                                      <td class="text-end"> UGX {subTotal}{/*{isNaN(tickeTotal) ? 0 : tickeTotal} */}</td>
                                    </tr>
                                    <tr>
                                      <td>Discount : </td>
                                      <td class="text-end"> UGX  0</td>
                                    </tr>
                                    <tr>
                                      <td>Shipping Charge :</td>
                                      <td class="text-end"> UGX 0</td>
                                    </tr>
                                    <tr>
                                      <td>Handling Fee : </td>
                                      <td class="text-end">UGX {isNaN(handlingFee) ? 0 : handlingFee}</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <th>Total :</th>
                                      <td class="text-end">
                                        <span class="fw-bold">
                                          UGX {tickeTotal}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/* <!-- end table-responsive --> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- end row --> */}

                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>


      </Modal>

    )
}

export default BuyTicket