import React, { Component } from 'react'
import { Link, useHistory } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';
import ajax from '../utils/ajax'
import functions from '../utils/functions';
import { encode as base64_encode } from 'base-64';
import Sliders from '../components/Sliders';
import { toUpper } from 'lodash'
import { Bars } from 'react-loader-spinner';

const evest = ["<div></div>", "<div></div>", "<div></div>"]
// const nav = useHistory();

class Home extends Component {

    state = {
        // userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        trending: false,
        eventSoon: false,
        dialogue: false
    }

    componentDidMount() {
        this.getLiveEvents();
        this.getLiveEventSoon();
        this.getAllFun();

        localStorage.setItem(
            'funug@user', "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlhdCI6MTY5ODE0NzU0NiwiZXhwIjoxNjk4MTQ3NjA2LCJ1c2VyX2lkIjoiMSIsInJvbGVfaWQiOiIxIiwicm9sZV9jb2RlIjoiQURNSU4iLCJpc19hY3RpdmUiOiIwIiwiaXNfc2VjdXJlIjoiMSIsInVzZXJuYW1lIjoicm9vdCIsImJvdW5kYXJ5IjoiTk9STUFMIiwiZnVsbF9uYW1lIjoiQWRtaW4ifX0.8Ayy6gnd2RAaLv0xjYRmtLhb7RkR4Afu9dd436fcJnY"
        )
    }

    getAllFun = async () => {
        const server_response = await ajax.getAllFun("0", this.state.userId);
        const trend = []

        if (server_response.status === "OK") {

            //console.log("kakakakk ", server_response)

            server_response.details.content.list.map((tren) => {
                trend.push({
                    tour_id: tren.tour_id,
                    tour_name: tren.tour_name,
                    located: tren.tour_location
                }
                )
            }
            )

            this.setState({
                allOldfunTrick: trend,
            })

        } else {
            this.setState({
                allfun: false,
                allOldfun: false,
                metaData: false,
                nodata: true
            })
        }

    }


    filterFun = (event) => {

        if (event.target.value != "") {

            const stat = this.state
            const currentValue = toUpper(event.target.value)
            this.setState({ searchWord: currentValue })
            const filteredData = stat.allOldfunTrick.filter(
                (entry) =>
                    toUpper(entry.tour_name).includes(currentValue) ||
                    toUpper(entry.located).includes(currentValue)

            )

            if (filteredData.length == 0) {
                this.setState({
                    nodata: true,
                    dialogue: false

                })
            }

            this.setState({
                allfun: filteredData,
                searchEvent: true,
                serachTerm: event.target.value,
                dialogue: true

            })

        } else {
            this.setState({
                allfun: this.state.fallback,
                searchEvent: false,
                nodata: false
            })
        }

    }

    getLiveEvents = async () => {
        const server_response = await ajax.getLiveEvents('1', '1', '1');
        const trend = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((tren) => {
                trend.push({
                    tour_id: tren.tour_id,
                    tour_name: tren.tour_name,
                    poster: tren.tour_image.file_path
                }
                )
            }
            )

            this.setState({
                trending: trend //server_response.details.content.list
            })

        } else {
            this.setState({
                trending: false
            })
        }

    }

    regsisterClick = async (idd) => {
        const server_response = await ajax.regsisterClick(idd);

        if (server_response.status === "OK") {
            //
        } else {
            //
        }

        setTimeout(
            () => {
                this.props.history.push({
                    pathname: `/fun-details/${base64_encode(base64_encode(idd))}`,
                    state: { tour_id: idd }
                })
            },
            250)

    }

    quicsearch = async (event) => {

        if (event.target.value != "") {

            this.setState({
                dialogue: true
            })

        } else {

            this.setState({
                dialogue: false
            })

        }
    }

    getLiveEventSoon = async () => {
        const server_response = await ajax.getLiveEvents('1', '1', '2');
        const trend = []

        if (server_response.status === "OK") {

            //console.log("uuuuuuuu",server_response)

            server_response.details.content.list.map((tren) => {
                trend.push({
                    tour_id: tren.tour_id,
                    tour_name: tren.tour_name,
                    poster: tren.tour_image.file_path,
                    dated: tren.tour_start_date.short_date
                }
                )
            }
            )

            this.setState({
                eventSoon: trend //server_response.details.content.list
            })

        } else {
            this.setState({
                eventSoon: false
            })
        }

    }

    render() {
        const eves = this.state.trending
        const soon = this.state.eventSoon
        const search = this.state.allfun
        // const aset =this.state.setalready
        return (
            <>

                <body className="datepicker_mobile_full">
                    {/* <!-- Remove this className to disable datepicker full on mobile --> */}

                    <div id="page">

                        <header className="header menu_fixed">
                            {/* <div id="preloader"><div data-loader="circle-side"></div></div> */}
                            {/* <!-- /Page Preload --> */}
                            <div id="logo">
                                <a href="/">
                                    <img src="assets/img/biglogo.png" width="100" height="50" alt="" className="logo_normal" />
                                    <img src="assets/img/biglogo.png" width="100" height="50" alt="" className="logo_sticky" />
                                </a>
                            </div>
                            <ul id="top_menu">
                                {/* <li><a href="cart-1.html" className="cart-menu-btn" title="Cart"><strong>4</strong></a></li> */}
                                <li><a data-bs-toggle="modal" href="#exampleModalToggle" id="sign-in" className="login" title="Sign In">Sign In</a></li>
                            </ul>
                            {/* <!-- /top_menu --> */}
                            <a href="#menu" className="btn_mobile">
                                <div className="hamburger hamburger--spin" id="hamburger">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </a>
                            <nav id="menu" className="main-menu">
                                <ul>
                                    <li><span><a href="/">Home</a></span>
                                    </li>
                                    <li><span><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></span></li>
                                    <li><span><a href="/how-it-works">How it works</a></span>
                                    </li>
                                    {/* <li><span><a href="#0">Hotels</a></span>
                                    </li>
                                    <li><span><a href="adventure.html">Adventure</a></span></li> */}
                                </ul>
                            </nav>
                        </header>
                        {/* <!-- /header --> */}

                        <main>
                            <section className="hero_single jarallax radio_cat" data-jarallax>
                                <img className="jarallax-img" src="assets/img/hero_in_bg_1.jpg" alt="" />
                                <div className="wrapper opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-10">
                                                <h3>Book unique experiences</h3>
                                                <p>Explore top rated and fun tours, adventures and festivals in uganda</p>
                                                <div className="switch-field">
                                                    <input type="radio" id="Tours" name="listing_filter" value="tours" />
                                                    <label for="Tours"><img src="assets/img/tours_icon_cat.svg" alt="" />Tours</label>
                                                    <input type="radio" id="Concerts" name="listing_filter" value="tours" />
                                                    <label for="Concerts"><img src="assets/img/concert.png" alt="" />Concerts</label>
                                                    <input type="radio" id="Hotels" name="listing_filter" value="hotels" />
                                                    <label for="Hotels"><img src="assets/img/roadtrips.png" alt="" />Road Trips</label>
                                                    <input type="radio" id="Adventure" name="listing_filter" value="adventure" />
                                                    <label for="Adventure"><img src="assets/img/adventure_icon_cat.svg" alt="" />Adventure</label>
                                                    <input type="radio" id="Festivals" name="listing_filter" value="hotels" />
                                                    <label for="Festivals"><img src="assets/img/fireworks.png" alt="" />Festivals</label>
                                                </div>
                                                <form>
                                                    <div className="row g-0 custom-search-input-2">
                                                        <div className="col-lg-8">
                                                            <div className="form-group">
                                                                <input onChange={this.filterFun} className="form-control" type="text" placeholder="Discover your fun ..." id="autocomplete" />
                                                                <i className="icon_search"></i>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4" id='searchbtnhome'>
                                                            <input type="submit" className="btn_search" value="Search" />
                                                        </div>

                                                    </div>

                                                    {
                                                        this.state.dialogue ? (
                                                            <>

                                                                <div className="col-lg-8 col-md-8 custom-search-input-2" style={{ marginTop: '10px', paddingLeft: '8px', backgroundColor: 'white' }}>
                                                                    <ol>

                                                                        {Array.isArray(search) ?
                                                                            (
                                                                                search.map((item, key) =>

                                                                                    <li onClick={() => this.regsisterClick(item.tour_id)} key={key} style={{ color: 'black', textAlign: 'left', paddingBottom: '10px',paddingTop:'5px' }}> <a href='#'><span style={{color:'red',letterSpacing: '0.1em'}}> {item.tour_name} </span>&nbsp; 📍 {item.located}</a></li>
                                                                                    
                                                                                )
                                                                            ) :
                                                                            (
                                                                                null
                                                                            )


                                                                        }
                                                                    </ol>
                                                                </div>

                                                            </>
                                                        ) : null
                                                    }

                                                    {/* <!-- /row --> */}
                                                </form>
                                            </div>
                                        </div>
                                        {/* <!-- /row --> */}
                                    </div>
                                    {/* <!-- /container --> */}
                                </div>
                            </section>
                            {/* <!-- /hero_single --> */}

                            <div className="container container-custom margin_80_0">
                                <div className="main_title_2">
                                    <span><em></em></span>
                                    <h2>Trending </h2>
                                    <p>See what every one is looking for.</p>
                                </div>

                                <div id="reccomended">
                                    <Sliders />
                                </div>

                                <hr className="medium" />
                            </div>
                            {/* <!-- /container --> */}

                            <div className="container container-custom margin_30_95">
                                <section className="add_bottom_45">
                                    <div className="main_title_3">
                                        <span><em></em></span>
                                        <h2>Happening soon</h2>
                                        <p >See whats Happening real soon.</p>
                                    </div>
                                    <div className="row">

                                        {Array.isArray(soon) ?
                                            (
                                                soon.map((item, key) =>

                                                    <div className="col-xl-3 col-lg-6 col-md-6" key={key} onClick={() => this.regsisterClick(item.tour_id)}>
                                                        <a className="grid_item"
                                                            to="#">
                                                            <figure>
                                                                <div className="score"><strong>{item.dated}</strong></div>
                                                                <img src={item.poster} className="img-fluid" alt="" style={{ height: '250px' }} />
                                                                <div className="info">
                                                                    <div className="cat_star"><i className="icon_star"></i><i className="icon_star"></i><i className="icon_star"></i><i className="icon_star"></i></div>
                                                                    <h3>{item.tour_name}</h3>
                                                                </div>
                                                            </figure>
                                                        </a>
                                                    </div>

                                                )
                                            ) :
                                            (
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Bars
                                                        height="70"
                                                        width="100"
                                                        color="#F21313"
                                                        ariaLabel="bars-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </div>
                                            )

                                        }
                                    </div>
                                    {/* <!-- /row --> */}
                                    {/* <a href="hotels-grid-isotope.html"><strong>View all (157) <i className="arrow_carrot-right"></i></strong></a> */}
                                    <p className="btn_home_align"><Link to="/happening-soon" className="btn_1 rounded">View all</Link></p>
                                </section>
                                {/* <!-- /section --> */}

                                <section className="add_bottom_45">
                                    <div className="main_title_3">
                                        <span><em></em></span>
                                        <h2>Explore more</h2>
                                        <p>Find and plan for your fun.</p>
                                    </div>
                                    <div className="row">

                                        {Array.isArray(soon) ? (

                                            soon.map((item, key) =>

                                                <div className="col-xl-3 col-lg-6 col-md-6" key={key} onClick={() => this.regsisterClick(item.tour_id)}>
                                                    <a className="grid_item"
                                                        to="#">
                                                        <figure>
                                                            <div className="score"><strong>{item.dated}</strong></div>
                                                            <img src={item.poster} className="img-fluid" alt="" style={{ height: '250px' }} />
                                                            <div className="info">
                                                                <div className="cat_star"><i className="icon_star"></i><i className="icon_star"></i><i className="icon_star"></i><i className="icon_star"></i></div>
                                                                <h3>{item.tour_name}</h3>
                                                            </div>
                                                        </figure>
                                                    </a>
                                                </div>

                                            )

                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Bars
                                                    height="70"
                                                    width="100"
                                                    color="#F21313"
                                                    ariaLabel="bars-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </div>
                                        )
                                        }

                                    </div>
                                    {/* <!-- /row --> */}
                                    {/* <a href="restaurants-grid-isotope.html"><strong>View all (157) <i className="arrow_carrot-right"></i></strong></a> */}
                                    <p className="btn_home_align"><Link to="/fun-list" className="btn_1 rounded">View all</Link></p>
                                </section>
                                {/* <!-- /section --> */}

                            </div>
                            {/* <!-- /container --> */}

                        </main>
                        {/* <!-- /main --> */}

                        <Footer />

                        {/* <!--/footer--> */}
                    </div>
                    {/* <!-- page --> */}

                    {/* <!-- Sign In Popup --> */}
                    {/* <div id="sign-in-dialog" className="modal zoom-anim-dialog mfp-hide"> */}
                    <div class="modal fade mfp-hide" id="sign-in-dialog" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">

                        <div className="small-dialog-header">
                            <h3>Sign In</h3>
                        </div>
                        <form>
                            <div className="sign-in-wrapper">
                                <a href="#0" className="social_bt facebook">Login with Facebook</a>
                                <a href="#0" className="social_bt google">Login with Google</a>
                                <div className="divider"><span>Or</span></div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email" id="email" />
                                    <i className="icon_mail_alt"></i>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" name="password" id="password" value="" />
                                    <i className="icon_lock_alt"></i>
                                </div>
                                <div className="clearfix add_bottom_15">
                                    <div className="checkboxes float-start">
                                        <label className="container_check">Remember me
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="float-end mt-1"><a id="forgot" href="javascript:void(0);">Forgot Password?</a></div>
                                </div>
                                <div className="text-center"><input type="submit" value="Log In" className="btn_1 full-width" /></div>
                                <div className="text-center">
                                    Don’t have an account? <a href="register.html">Sign up</a>
                                </div>
                                <div id="forgot_pw">
                                    <div className="form-group">
                                        <label>Please confirm login email below</label>
                                        <input type="email" className="form-control" name="email_forgot" id="email_forgot" />
                                        <i className="icon_mail_alt"></i>
                                    </div>
                                    <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
                                    <div className="text-center"><input type="submit" value="Reset Password" className="btn_1" /></div>
                                </div>
                            </div>
                        </form>
                        {/* <!--form --> */}
                    </div>
                    {/* <!-- /Sign In Popup --> */}

                    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title fs-5" id="exampleModalToggleLabel">Sign In</h3>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form>
                                        <div className="sign-in-wrapper">
                                            <a href="#0" className="social_bt facebook">Login with Facebook</a>
                                            <a href="#0" className="social_bt google">Login with Google</a>
                                            <div className="divider"><span>Or</span></div>
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" name="email" id="email" />
                                                <i className="icon_mail_alt"></i>
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input type="password" className="form-control" name="password" id="password" value="" />
                                                <i className="icon_lock_alt"></i>
                                            </div>
                                            <div className="clearfix add_bottom_15">
                                                <div className="checkboxes float-start">
                                                    <label className="container_check">Remember me
                                                        <input type="checkbox" />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="float-end mt-1"><a id="forgot" href="javascript:void(0);">Forgot Password?</a></div>
                                            </div>
                                            <div className="text-center"><input type="submit" value="Log In" className="btn_1 full-width" /></div>
                                            <div className="text-center">
                                                Don’t have an account? <a href="register.html">Sign up</a>
                                            </div>
                                            <div id="forgot_pw">
                                                <div className="form-group">
                                                    <label>Please confirm login email below</label>
                                                    <input type="email" className="form-control" name="email_forgot" id="email_forgot" />
                                                    <i className="icon_mail_alt"></i>
                                                </div>
                                                <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
                                                <div className="text-center"><input type="submit" value="Reset Password" className="btn_1" /></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="toTop"></div>


                </body>


            </>
        )
    }
}
export default Home