import React, { Component } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';
import ajax from '../utils/ajax'
import { toUpper } from 'lodash'
import $ from 'jquery';
import functions from '../utils/functions'


class HowItWorks extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        tourId: '',
        tourName: '',
        tourPoster: '',
        tourDesc: '',
        priced: '00 UGX',
        trending: false,
        allfun: false,
        allOldfun: false,
        dialog: false,
        currentPage: 1,
        metaData: false,
        processing: false,
        allOldfunTrick: false,
        searchEvent: false,
        fallback: false,
        nodata: false,
        serachTerm: ""

    }

    componentDidMount() {

        // if (!this.props.match.params) {
        //     this.props.history.push('/')
        // } else {
        //     this.setState(
        //         {
        //             tourId: this.props.match.params.fun,
        //         },
        //         () => {
        //             this.getEventInfo()
        //         }
        //     )
        // }
    }

    render() {

        const soon = this.state.allfun

        return (
            <>

                <body className="datepicker_mobile_full">
                    {/* <!-- Remove this className to disable datepicker full on mobile --> */}

                    <div id="page">

                        <header className="header menu_fixed">
                            {/* <div id="preloader"><div data-loader="circle-side"></div></div> */}
                            {/* <!-- /Page Preload --> */}
                            <div id="logo">
                                <a href="/">
                                <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_normal" />
                                <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_sticky" />
                                </a>
                            </div>
                            <ul id="top_menu">
                                {/* <li><a href="cart-1.html" className="cart-menu-btn" title="Cart"><strong>4</strong></a></li> */}
                                <li><a href="#sign-in-dialog" id="sign-in" className="login" title="Sign In">Sign In</a></li>
                            </ul>
                            {/* <!-- /top_menu --> */}
                            <a href="#menu" className="btn_mobile">
                                <div className="hamburger hamburger--spin" id="hamburger">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </a>
                            <nav id="menu" className="main-menu">
                                <ul>
                                    <li><span><a href="/">Home</a></span>
                                    </li>
                                    <li><span><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></span></li>
                                    <li><span><a href="how-it-works">How it Works</a></span>
                                    </li>
                                    {/* <li><span><a href="#0">Hotels</a></span>
                                    </li>
                                    <li><span><a href="adventure.html">Adventure</a></span></li> */}
                                </ul>
                            </nav>
                        </header>
                        {/* <!-- /header --> */}


                        <main>
                            <section class="hero_in contacts">
                                <div class="wrapper">
                                    <div class="container">
                                        <h1 class="fadeInUp"><span></span>how it works</h1>
                                    </div>
                                </div>
                            </section>
                            {/* <!--/hero_in--> */}

                            <div class="contact_info">
                                <div class="container">
                                    <ul class="clearfix">
                                        <li>
                                            <i class="pe-7s-map-marker"></i>
                                            <h4>Address</h4>
                                            <span>PO Box 97845 Bahai Road, Kikaya<br />Kampala - Uganda.</span>
                                        </li>
                                        <li>
                                            <i class="pe-7s-mail-open-file"></i>
                                            <h4>Email address</h4>
                                            <span>support@funug.com -  tickets@funug.co<br /><small>Monday to Sunday 9am - 7pm</small></span>

                                        </li>
                                        <li>
                                            <i class="pe-7s-phone"></i>
                                            <h4>Contacts info</h4>
                                            <span>+ 256 39 300 0417 + 256 39 300 0417<br /><small>Monday to Sunday 9am - 7pm</small></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--/contact_info--> */}

                            {/* <div class="bg_color_1"> */}
                            <div class="container margin_80_55">
                                <div class="main_title_2">
                                    <span><em></em></span>
                                    <h2>Ticketing Simplified</h2>
                                    <p>Publish Your Tickets In Three Simple Steps.</p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <a class="box_feat" href="#0">
                                            <i class="pe-7s-medal"></i>
                                            <h3>Submit Your Event</h3>
                                            <p>To make sure that we have only legit fun events listed on our platform, for now only verified organizers can self submit events.</p>
                                        </a>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <a class="box_feat" href="#0">
                                            <i class="pe-7s-help2"></i>
                                            <h3>Choose How To Be Paid</h3>
                                            <p>At funug we know what money means to our clients, so we let you choose how you want to collect with flowless transfers out. </p>
                                        </a>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <a class="box_feat" href="#0">
                                            <i class="pe-7s-culture"></i>
                                            <h3>How To Get Verified</h3>
                                            <p>Submit your kyc details to kyc@qubitsanalytic.com and we will give you access to our platform to self submit your fun events</p>
                                        </a>
                                    </div>
                                </div>
                                {/* <!--/row--> */}
                            </div>
                                {/* <!-- /container --> */}
                            {/* </div> */}
                            {/* <!-- /bg_color_1 --> */}
                        </main>


                        {/* <!-- /main --> */}

                        <Footer />

                        {/* <!--/footer--> */}
                    </div>
                    {/* <!-- page --> */}

                </body>
            </>
        )
    }
}
export default HowItWorks