import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Home from './pages/Home';
import Details from './pages/Details';
import Ticket from './pages/Ticket';
import Tickets from './pages/Tickets';
import ListTours from './pages/ListTours';
import HappeingSoon from './pages/HappeingSoon';
import HowItWorks from './pages/HowItWorks';
import About from './pages/About';


class App extends Component {


  state = {
    screen: "Login",
    loggedIn: true,
    // accessInfo: functions.permissionGuard()
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.getItem("funug@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }

  render() {
    // const roleList = functions.roleGuard()
    return (
      <Router forceRefresh={true}>

        <Switch>
          {!this.state.loggedIn && <Route exact path="/" component={Home} />}
          <Route exact path="/" component={Home} />
          <Route exact path="/fun-details/:fun" component={Details} />
          <Route exact path="/your/ticket" component={Ticket} />
          <Route exact path="/fun-list" component={ListTours} />
          <Route exact path="/happening-soon" component={HappeingSoon} />
          <Route exact path="/how-it-works" component={HowItWorks} />
          <Route exact path="/about-funug" component={About} />


        </Switch>
      </Router>
    )
  }
}
export default App

