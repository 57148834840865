import React, { Component, useEffect, useState, CSSProperties } from 'react'
import functions from '../utils/functions'
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ajax from '../utils/ajax';
import { encode as base64_encode } from 'base-64';
import { Bars } from 'react-loader-spinner';


const Sliders = (props) => {


    const [mostClickList, setMostClickList] = useState(false);
    const [userId, setUserId] = useState(functions.sessionGuard())
    const nav = useHistory();


    const getMostClicked = async (idd) => {
        const server_response = await ajax.getMostClicked(idd);
        // console.log("dafadfa", server_response)
        if (server_response.status === "OK") {

            setMostClickList(server_response.details.content)
        } else {
            setMostClickList(false)
        }
    }

    const regsisterClick = async (idd) => {
        const server_response = await ajax.regsisterClick(idd);

        if (server_response.status === "OK") {
            //
        } else {
            //
        }

        setTimeout(
            () => {
                nav.push({
                    pathname: `/fun-details/${base64_encode(base64_encode(idd))}`,
                    state: { tour_id: idd }
                })
            },
            250)
    }

    useEffect(() => {
        // alert(userId)
        if (userId) {
            getMostClicked(userId)
        }
    }, [userId])

    const settings = {
        dots: true,
        speed: 1000,
        autoplaySpeed: 5000,
        autoplay: true,
        infinite: true,
        slidesToShow: mostClickList.length < 4 ? 2 : 4,
        pauseOnHover: true,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: mostClickList.length < 3 ? 2 : 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },


            {
                breakpoint: 600,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 540,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    centerMode: false,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>


            {mostClickList && mostClickList !== "404" ?  //&& mostClickList.length >= 4
                (
                    <div >
                        <Slider {...settings}>
                            {mostClickList.map(function (item, key) {
                                return (
                                    <div key={key} onClick={() => regsisterClick(item.tour_id)}>
                                        <div class="item" >
                                            <div class="box_grid">
                                                <figure>
                                                    <a href="#"></a>
                                                    <a to="#" >
                                                        <img src={item.tour_image.file_path} class="img-fluid" alt="" width="800" height="533" /><div class="read_more"><span>Read more</span></div></a>
                                                    <small>{item.tour_start_date.short_date}</small>
                                                </figure>
                                                <div class="wrapper">
                                                    <h3><a to="#">{item.tour_name.length > 23 ? item.tour_name.substring(0, 21) + " ..." : item.tour_name}</a></h3>
                                                    <span class="price"><i className="icon_pin_alt"></i> <strong>{item.tour_location}</strong></span>
                                                </div>
                                                <ul>
                                                    <li><i class="icon_list"></i> <strong>


                                                        {item.price_list[0].price.total == 0 ? (
                                                            "FREE FUN"
                                                        ) : "TICKETS START"}

                                                    </strong></li>
                                                    <li><div class="score"><strong>UGX{item.price_list[0].price.total}</strong></div></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                ) :
                (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Bars
                            height="70"
                            width="100"
                            color="#F21313"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                )

            }
        </div>
    )
}
export default Sliders