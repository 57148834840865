import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';
import ajax from '../utils/ajax'
import $ from 'jquery';
import functions from '../utils/functions'
import AvailableTics from '../modals/AvailableTics';
import BuyTicket from '../modals/BuyTicket';
import { decode as base64_decode } from 'base-64';
import PhotoViewer from 'photoviewer';

const evest = ["<div></div>", "<div></div>", "<div></div>"]

class Details extends Component {

    state = {
        // userId: functions.sessionGuard(),
        username: '',
        tourId: '',
        tourName: '',
        tourTypeId: '',
        tourPoster: '',
        tourDesc: '',
        priceList: [],
        ticketTypes: [],
        priced: '00 UGX',
        trending: false,
        eventSoon: false,
        dialog: false,
        tourStatus: "0"

    }

    // shouldComponentUpdate = ()=>false

    componentDidMount() {
        if (!this.props.match.params) {
            this.props.history.push('/')
        } else {
            //console.log("kjkjkjk d ", this.props.location.state.tour_id)
            this.setState(
                {
                    tourId: base64_decode(base64_decode(this.props.match.params.fun)), //this.props.location.state.tour_id
                },
                () => {
                    this.getEventInfo()
                }
            )
        }
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    onClickRegister = () => {
        this.setCartItem(this.state.priceList)
        this.setState({
            dialog: true
        })
    }

    setCartItem = (priceList) => {

        const items = []
        priceList.map((tren, key) => {
            items.push({
                id: key,
                priceId: tren.price_tag_id,
                amount: tren.price.total,
                category: tren.price_cat.price_cat_name,
                quantity: 0,
                total: 0,
                subtotal: 0,
                handlingFee: 0,
                fullname: "",
                phoneNumber: "",
                emailAdd: ""
            })
        })

        //console.log("juukoo ",priceList)

        this.setState({
            ticketTypes: items
        })


    }

    getEventInfo = async () => {
        const server_response = await ajax.getEventInfo(this.state.tourId);
        const trend = []

        if (server_response.status === "OK") {

            // console.log("jumiaUganda", server_response.details.content.tour_type.tour_type_id)

            this.setState({
                tourName: server_response.details.content.tour_name,
                tourTypeId: server_response.details.content.tour_type.tour_type_id,
                tourPoster: server_response.details.content.tour_image.file_path,
                priced: server_response.details.content.price_list[0].price.total,
                priceList: server_response.details.content.price_list,
                tourDesc: server_response.details.content.tour_desc,
                dated: server_response.details.content.tour_start_date.short_date,
                located: server_response.details.content.tour_location,
                closed: server_response.details.content.tour_end_date,
                tourStatus: server_response.details.content.tour_status

            })


        } else {
            // this.setState({
            //     trending: false
            // })
        }

    }

    render() {
        //console.log("jkjsksjsk",base64_decode(base64_decode(this.state.tourId)))
        return (
            <>

                <body className="datepicker_mobile_full">
                    {/* <!-- Remove this className to disable datepicker full on mobile --> */}

                    <div id="page">

                        <header className="header menu_fixed" style={{ zIndex: '900' }}>
                            {/* <div id="preloader"><div data-loader="circle-side"></div></div> */}
                            {/* <!-- /Page Preload --> */}
                            <div id="logo">
                                <a href="/">
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_normal" />
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_sticky" />
                                </a>
                            </div>
                            <ul id="top_menu">
                                {/* <li><a href="cart-1.html" className="cart-menu-btn" title="Cart"><strong>4</strong></a></li> */}
                                <li><a href="#sign-in-dialog" id="sign-in" className="login" title="Sign In">Sign In</a></li>
                            </ul>
                            {/* <!-- /top_menu --> */}
                            <a href="#menu" className="btn_mobile">
                                <div className="hamburger hamburger--spin" id="hamburger">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </a>
                            <nav id="menu" className="main-menu">
                                <ul>
                                    <li><span><a href="/">Home</a></span>
                                    </li>
                                    <li><span><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></span></li>
                                    <li><span><a href="/how-it-works">How it Works</a></span>
                                    </li>
                                    {/* <li><span><a href="#0">Hotels</a></span>
                                    </li>
                                    <li><span><a href="adventure.html">Adventure</a></span></li> */}
                                </ul>
                            </nav>

                        </header>
                        {/* <!-- /header --> */}


                        <main>
                            <section class="hero_in tours_detail" style={{ background: `url(${this.state.tourPoster}) center center/cover no-repeat` }}>
                                <div class="wrapper">
                                    <div class="container">
                                        <h1 class="fadeInUp"><span></span>{this.state.tourName}</h1>
                                    </div>
                                    <span class="magnific-gallery">
                                        <a href="../assets/img/gallery/tour_list_1.jpg" class="btn_photos" title="Photo title" data-effect="mfp-zoom-in">View photos</a>
                                        <a href="../assets/img/gallery/tour_list_2.jpg" title="Photo title" data-effect="mfp-zoom-in"></a>
                                        <a href="../assets/img/gallery/tour_list_3.jpg" title="Photo title" data-effect="mfp-zoom-in"></a>
                                    </span>
                                </div>
                            </section>
                            {/* <!--/hero_in--> */}

                            <div class="bg_color_1">
                                <nav class="secondary_nav sticky_horizontal">
                                    <div class="container">
                                        <ul class="clearfix">
                                            <li><a href="#description" class="active">Description</a></li>
                                        </ul>
                                    </div>
                                </nav>
                                <div class="container margin_60_35">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <section id="description">
                                                <h2>Description</h2>
                                                <p>{this.state.tourDesc}</p>
                                                {/* <p>Cum et probo menandri. Officiis consulatu pro et, ne sea sale invidunt, sed ut sint <strong>blandit</strong> efficiendi. Atomorum explicari eu qui, est enim quaerendum te. Quo harum viris id. Per ne quando dolore evertitur, pro ad cibo commune.</p> */}

                                                <div class="pictures_grid magnific-gallery clearfix">

                                                </div>

                                            </section>
                                        </div>
                                        {/* <!-- /col --> */}

                                        <aside class="col-lg-4" id="sidebar">
                                            <div class="box_detail booking">
                                                <div class="price">

                                                    {(this.state.priceList).length > 1 ? (
                                                        <span>From UGX {this.state.priced} </span>
                                                    ) : <span>UGX {this.state.priced} </span>}

                                                    {/* <div class="score"><span>Good<em>350 Reviews</em></span><strong>7.0</strong></div> */}
                                                </div>
                                                <div class="price">

                                                    <p style={{ color: 'red', fontSize: '15px' }}><i class="icon_calendar"></i>&nbsp; &nbsp; {this.state.dated} </p>
                                                    <p style={{ color: 'green', fontSize: '15px' }}><i class="icon_pin_alt"></i>&nbsp; &nbsp; {this.state.located} </p>
                                                </div>
                                                {
                                                    this.state.tourStatus == "1" ?
                                                        <a href="#exampleModalToggle" data-bs-toggle="modal" data-bs-backdrop="static" onClick={() => this.setCartItem(this.state.priceList)} data-bs-keyboard="false" class="btn_1 full-width purchase">Purchase</a>
                                                        :
                                                        <a class="btn_1 full-width purchase" style={{backgroundColor:'gray'}}>Oops! Sales Closed</a>
                                                }
                                                {/* <a href="#exampleModalToggle" data-bs-toggle="modal" data-bs-backdrop="static" onClick={() => this.setCartItem(this.state.priceList)} data-bs-keyboard="false" class="btn_1 full-width purchase">Purchase</a> */}
                                                {/* <button type="button" onClick={this.onClickRegister} class="btn_1 full-width purchase">{this.state.tourStatus}</button> */}
                                                <div class="text-center"><small>No money charged in this step</small></div>
                                            </div>
                                        </aside>
                                    </div>
                                    {/* <!-- /row --> */}
                                </div>
                                {/* <!-- /container --> */}
                            </div>
                            {/* <!-- /bg_color_1 --> */}
                        </main>

                        {/* <!-- /main --> */}

                        <footer>
                            <div className="container margin_60_35">
                                <div className="row">
                                    <div className="col-lg-5 col-md-12 pe-5">
                                        <p><img src="../assets/img/biglogo.png" width="100" height="50" alt="" /></p>
                                        <p>
                                            We are a funug an event listing and ticketing platform.
                                        </p>
                                        <div className="follow_us">
                                            <ul>
                                                <li>Follow us</li>
                                                <li><a href="#0"><i className="ti-facebook"></i></a></li>
                                                <li><a href="#0"><i className="ti-twitter-alt"></i></a></li>
                                                <li><a href="#0"><i className="ti-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 ms-lg-auto">
                                        <h5>Useful links</h5>
                                        <ul className="links">
                                            <li><a href="/about-funug">About</a></li>
                                            <li><a href="#">Login</a></li>
                                            <li><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></li>
                                            {/* <li><a href="blog.html">News &amp; Events</a></li> */}
                                            {/* <li><a href="contacts.html">Contacts</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <h5>Contact with Us</h5>
                                        <ul className="contacts">
                                            <li><a href="tel://61280932400"><i className="ti-mobile"></i> + 256 39 300 0417</a></li>
                                            <li><a href="mailto:info@Panagea.com"><i className="ti-email"></i>  tickets@funug.co</a></li>
                                        </ul>

                                    </div>
                                </div>
                                <hr />

                            </div>
                        </footer>
                        {/* <!--/footer--> */}
                    </div>
                    {/* <!-- page --> */}

                    {/* <!-- Sign In Popup --> */}
                    <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
                        <div className="small-dialog-header">
                            <h3>Sign In</h3>
                        </div>
                        <form>
                            <div className="sign-in-wrapper">
                                <a href="#0" className="social_bt facebook">Login with Facebook</a>
                                <a href="#0" className="social_bt google">Login with Google</a>
                                <div className="divider"><span>Or</span></div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email" id="email" />
                                    <i className="icon_mail_alt"></i>
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" name="password" id="password" value="" />
                                    <i className="icon_lock_alt"></i>
                                </div>
                                <div className="clearfix add_bottom_15">
                                    <div className="checkboxes float-start">
                                        <label className="container_check">Remember me
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="float-end mt-1"><a id="forgot" href="javascript:void(0);">Forgot Password?</a></div>
                                </div>
                                <div className="text-center"><input type="submit" value="Log In" className="btn_1 full-width" /></div>
                                <div className="text-center">
                                    Don’t have an account? <a href="register.html">Sign up</a>
                                </div>
                                <div id="forgot_pw">
                                    <div className="form-group">
                                        <label>Please confirm login email below</label>
                                        <input type="email" className="form-control" name="email_forgot" id="email_forgot" />
                                        <i className="icon_mail_alt"></i>
                                    </div>
                                    <p>You will receive an email containing a link allowing you to reset your password to a new preferred one.</p>
                                    <div className="text-center"><input type="submit" value="Reset Password" className="btn_1" /></div>
                                </div>
                            </div>
                        </form>
                        {/* <!--form --> */}
                    </div>
                    {/* <!-- /Sign In Popup --> */}

                    {/* <div id="toTop"></div> */}

                    {this.state.dialog ? (
                        <BuyTicket
                            visible={{ visible: true }}
                            handleCancel={this.recievedState}
                            price={this.state.priced} image={this.state.tourPoster} tourName={this.state.tourName} when={this.state.dated} close={this.state.closed} priceList={this.state.priceList} ticketTypes={this.state.ticketTypes}
                        />
                    ) : null}

                    <AvailableTics price={this.state.priced} image={this.state.tourPoster} tourName={this.state.tourName} when={this.state.dated} close={this.state.closed} priceList={this.state.priceList} ticketTypes={this.state.ticketTypes} tourId={this.state.tourId} tourTypeId={this.state.tourTypeId} />


                </body>

            </>
        )
    }
}
export default Details