import React, { Component } from 'react'

class NoDataFound extends Component {

    render() {
        return (
            <>

                <div class="row justify-content-center text-center">

                    <div class="col-xl-7 col-lg-9">
                    <h2 style={{marginTop:'30px',fontSize:'8.78rem',color:'#fff',textTransform:'uppercase',fontWeight:'700',lineHeight:'1'}}><i class="icon_error-triangle_alt" style={{color: '#ffc107'}}></i></h2>
                        <p style={{fontSize: '1.3125rem'}}>No Data Matching "{this.props.term.tellme}".</p>
                    </div>

                </div>
            </>
        )
    }
}
export default NoDataFound