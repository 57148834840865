import React, { Component } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';
import ajax from '../utils/ajax'
import $ from 'jquery';
import functions from '../utils/functions'
import QRCode from "react-qr-code";


class Ticket extends Component {

    state = {
        // userId: functions.sessionGuard(),
        username: '',
        tourId: '',
        tourName: '',
        tourPoster: '',
        tourDesc: '',
        priced: '00 UGX',
        trending: false,
        eventSoon: false,
        dialog: false,

    }

    componentDidMount() {


        console.log("kjkjkjk d ", this.props.location.state)


        // if (!this.props.match.params) {
        //     this.props.history.push('/')
        // } else {
        //     this.setState(
        //         {
        //             tourId: this.props.match.params.fun,
        //         },
        //         () => {
        //             this.getEventInfo()
        //         }
        //     )
        // }
    }

    recievedState = (data) => {
        this.setState({ dialog: data })
    }

    onClickRegister = () => {
        this.setState({
            dialog: true
        })
    }

    getEventInfo = async () => {
        const server_response = await ajax.getEventInfo("5"); {/*this.state.tourId*/}
        const trend = []

        if (server_response.status === "OK") {

            this.setState({
                tourName: server_response.details.content.tour_name,
                tourPoster: server_response.details.content.tour_image.file_path,
                priced: server_response.details.content.price_id.price.total,
                tourDesc: server_response.details.content.tour_desc,
                dated: server_response.details.content.tour_start_date,
                located: server_response.details.content.tour_location
            })


        } else {
            // this.setState({
            //     trending: false
            // })
        }

    }

    render() {

        return (
            <>

                <body className="datepicker_mobile_full">
                    {/* <!-- Remove this className to disable datepicker full on mobile --> */}

                    <div id="page">

                        <header className="header menu_fixed">
                            {/* <div id="preloader"><div data-loader="circle-side"></div></div> */}
                            {/* <!-- /Page Preload --> */}
                            <div id="logo">
                                <a href="/">
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_normal" />
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_sticky" />
                                </a>
                            </div>
                            <ul id="top_menu">
                                {/* <li><a href="cart-1.html" className="cart-menu-btn" title="Cart"><strong>4</strong></a></li> */}
                                <li><a href="#sign-in-dialog" id="sign-in" className="login" title="Sign In">Sign In</a></li>
                            </ul>
                            {/* <!-- /top_menu --> */}
                            <a href="#menu" className="btn_mobile">
                                <div className="hamburger hamburger--spin" id="hamburger">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </a>
                            <nav id="menu" className="main-menu">
                                <ul>
                                    <li><span><a href="/">Home</a></span>
                                    </li>
                                    <li><span><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></span></li>
                                    <li><span><a href="#0">How it Works</a></span>
                                    </li>
                                    {/* <li><span><a href="#0">Hotels</a></span>
                                    </li>
                                    <li><span><a href="adventure.html">Adventure</a></span></li> */}
                                </ul>
                            </nav>
                        </header>
                        {/* <!-- /header --> */}


                        <main>

                            <section class="hero_in general">
                                <div class="wrapper">
                                    <div class="container">
                                        {/* <h1 class="fadeInUp"><span></span>About Panagea</h1> */}
                                    </div>
                                </div>
                            </section>
                            {/* <!--/hero_in--> */}

                            <div class="container margin_80_55">
                                <div class="main_title_2">
                                    <span><em></em></span>
                                    <h2>Your Ticket</h2>
                                    <p>We emailed you a copy of this ticket.</p>
                                </div>

                                <div class="row">
                                    <div class="col-lg-1 col-sm-1 col-xl-1"></div>
                                    <div class="col-lg-9 col-sm-9 col-xl-9">

                                        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"/> */}

                                        <div id="ticket">
                                            <div id="left">
                                                <div id="image" style={{ backgroundImage: `url(${this.state.tourPoster})` }}>
                                                    <p id="admit-one">
                                                        <span>ADMIT ONE</span>
                                                        <span>ADMIT ONE</span>
                                                        <span>ADMIT ONE</span>
                                                    </p>
                                                    <div id="ticket-number">
                                                        <p>
                                                            #20030220
                                                        </p>
                                                    </div>
                                                </div>
                                                <div id="ticket-info">
                                                    <p id="date">
                                                        <span>TUESDAY</span>
                                                        <span id="june-29">JUNE 29TH</span>
                                                        <span>2021</span>
                                                    </p>
                                                    <div id="show-name">
                                                        <h1>{this.state.tourName}</h1>
                                                        <h2>Blankets And Wine</h2>
                                                    </div>
                                                    <div id="time">
                                                        <p>4:00 PM <span>TILL</span> LATE</p>
                                                        {/* <p>DOORS <span>@</span> 7:00 PM</p> */}
                                                        <p>Holder: Abdalla Wanjusi</p>
                                                    </div>

                                                    {/* <div id="tagline">
                                                            <p>Holder: Abdalla Wanjusi</p>
                                                        </div> */}
                                                    <p id="location"><span>Lugogo cricket oval &nbsp; &nbsp;</span>
                                                        <span id="separator"><i id="far fa-smile"></i></span><span>Kampala Uganda</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="right">
                                                <p id="admit-one">
                                                    <span>ADMIT ONE</span>
                                                    <span>ADMIT ONE</span>
                                                    <span>ADMIT ONE</span>
                                                </p>
                                                <div id="right-info-container">
                                                    <div id="show-name">
                                                        <h1>{this.state.tourName}</h1>
                                                    </div>
                                                    <div id="time">
                                                        <p>8:00 PM <span>TO</span> 11:00 PM</p>
                                                        <p>DOORS <span>@</span> 7:00 PM</p>
                                                    </div>
                                                    <div id="barcode">

                                                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 90, width: "100%" }}>
                                                            <QRCode
                                                                size={256}
                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                value='Where: Nyungu Yamawe Forest Park\nWhen: 21 Dec 2023\nHolder: Abdalla W.'
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </div>

                                                        {/* <img src="https://external-preview.redd.it/cg8k976AV52mDvDb5jDVJABPrSZ3tpi1aXhPjgcDTbw.png?auto=webp&s=1c205ba303c1fa0370b813ea83b9e1bddb7215eb" alt="QR code"/> */}
                                                    </div>
                                                    <p id="ticket-number">
                                                        #20030220
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-1 col-sm-1 col-xl-1"></div>

                                </div>
                                {/* <!--/row--> */}
                            </div>
                            {/* <!-- /container --> */}

                        </main>

                        {/* <!-- /main --> */}

                        <footer>
                            <div className="container margin_60_35">
                                <div className="row">
                                    <div className="col-lg-5 col-md-12 pe-5">
                                        <p><img src="../assets/img/biglogo.png" width="100" height="50" alt="" /></p>
                                        <p>
                                            We are a funug an event listing and ticketing platform.
                                        </p>
                                        <div className="follow_us">
                                            <ul>
                                                <li>Follow us</li>
                                                <li><a href="#0"><i className="ti-facebook"></i></a></li>
                                                <li><a href="#0"><i className="ti-twitter-alt"></i></a></li>
                                                <li><a href="#0"><i className="ti-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 ms-lg-auto">
                                        <h5>Useful links</h5>
                                        <ul className="links">
                                            <li><a href="/about-funug">About</a></li>
                                            <li><a href="#">Login</a></li>
                                            <li><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></li>
                                            {/* <li><a href="blog.html">News &amp; Events</a></li> */}
                                            {/* <li><a href="contacts.html">Contacts</a></li> */}
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <h5>Contact with Us</h5>
                                        <ul className="contacts">
                                            <li><a href="tel://+256394510524"><i className="ti-mobile"></i> + 256 39 300 0417</a></li>
                                            <li><a href="mailto: tickets@funug.co"><i className="ti-email"></i>  tickets@funug.co</a></li>
                                        </ul>

                                    </div>
                                </div>
                                <hr />

                            </div>
                        </footer>
                        {/* <!--/footer--> */}
                    </div>
                    {/* <!-- page --> */}


                </body>


            </>
        )
    }
}
export default Ticket