import React, { Component } from 'react'
import { Link, useLocation } from 'react-router-dom';
import ajax from '../utils/ajax'
import $ from 'jquery';
import functions from '../utils/functions'
import AvailableTics from '../modals/AvailableTics';
import BuyTicket from '../modals/BuyTicket';
import { encode as base64_encode } from 'base-64'
import { Bars } from 'react-loader-spinner';
import Footer from '../common/Footer';



class HappeingSoon extends Component {

    state = {
        // userId: functions.sessionGuard(),
        username: '',
        tourId: '',
        tourName: '',
        tourPoster: '',
        tourDesc: '',
        priced: '00 UGX',
        trending: false,
        allfun: false,
        dialog: false,

    }

    componentDidMount() {

        this.getAllFun()

        // if (!this.props.match.params) {
        //     this.props.history.push('/')
        // } else {
        //     this.setState(
        //         {
        //             tourId: this.props.match.params.fun,
        //         },
        //         () => {
        //             this.getEventInfo()
        //         }
        //     )
        // }
    }

    getAllFun = async () => {
        const server_response = await ajax.getAllFun('1', '1', '2');
        const trend = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((tren) => {
                trend.push({
                    tour_id: tren.tour_id,
                    tour_name: tren.tour_name,
                    poster: tren.tour_image.file_path,
                    dated: tren.tour_start_date.short_date,
                    located: tren.tour_location,
                    priced: tren.price_list[0].price.total
                }
                )
            }
            )

            this.setState({
                allfun: trend //server_response.details.content.list
            })

        } else {
            this.setState({
                allfun: false
            })
        }

    }

    regsisterClick = async (idd) => {
        const server_response = await ajax.regsisterClick(idd);

        if (server_response.status === "OK") {
            //
        } else {
            //
        }

        setTimeout(
            () => {
                this.props.history.push({
                    pathname: `/fun-details/${base64_encode(base64_encode(idd))}`,
                    state: { tour_id: idd }
                })
            },
            250)

    }

    render() {

        const soon = this.state.allfun

        return (
            <>

                <body className="datepicker_mobile_full">
                    {/* <!-- Remove this className to disable datepicker full on mobile --> */}

                    <div id="page">

                        <header className="header menu_fixed">
                            {/* <div id="preloader"><div data-loader="circle-side"></div></div> */}
                            {/* <!-- /Page Preload --> */}
                            <div id="logo">
                                <a href="/">
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_normal" />
                                    <img src="../assets/img/biglogo.png" width="100" height="50" alt="" className="logo_sticky" />
                                </a>
                            </div>
                            <ul id="top_menu">
                                {/* <li><a href="cart-1.html" className="cart-menu-btn" title="Cart"><strong>4</strong></a></li> */}
                                <li><a href="#sign-in-dialog" id="sign-in" className="login" title="Sign In">Sign In</a></li>
                            </ul>
                            {/* <!-- /top_menu --> */}
                            <a href="#menu" className="btn_mobile">
                                <div className="hamburger hamburger--spin" id="hamburger">
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </a>
                            <nav id="menu" className="main-menu">
                                <ul>
                                    <li><span><a href="/">Home</a></span>
                                    </li>
                                    <li><span><a href="http://myportal.funug.co/" target='__blank'>Add Fun Event</a></span></li>
                                    <li><span><a href="/how-it-works">How it Works</a></span>
                                    </li>
                                    {/* <li><span><a href="#0">Hotels</a></span>
                                    </li>
                                    <li><span><a href="adventure.html">Adventure</a></span></li> */}
                                </ul>
                            </nav>
                        </header>
                        {/* <!-- /header --> */}


                        <main>

                            <section class="hero_in tours">
                                <div class="wrapper">
                                    <div class="container">
                                        <h1 class="fadeInUp"><span></span>Enjoyment about to happen</h1>
                                    </div>
                                </div>
                            </section>
                            {/* <!--/hero_in--> */}

                            <div class="collapse" id="collapseMap">
                                <div id="map" class="map"></div>
                            </div>
                            {/* <!-- End Map --> */}

                            <div class="container margin_60_35">
                                <div class="row">

                                    <div class="col-lg-12">
                                        <div class="isotope-wrapper">

                                            <div class="row">


                                                {Array.isArray(soon) ?
                                                    (
                                                        soon.map((item, key) =>

                                                            <div class="col-xl-3 col-lg-6 col-md-6 isotope-item popular" key={key} onClick={() => this.regsisterClick(item.tour_id)}>
                                                                <div class="box_grid">
                                                                    <figure>
                                                                        {/* <a href="#0" class="wish_bt"></a> */}
                                                                        <a to="#">
                                                                            <img src={item.poster} class="img-fluid" alt="" width="800" height="533" />
                                                                        </a>
                                                                        <small>{item.dated}</small>
                                                                    </figure>
                                                                    <div class="wrapper">
                                                                        <h3>
                                                                            <a to="#">{item.tour_name.length > 23 ? item.tour_name.substring(0, 21) + " ..." : item.tour_name}</a>
                                                                        </h3>
                                                                        <span class="price"><i className="icon_pin_alt"></i> <strong>{item.located}</strong></span>
                                                                    </div>
                                                                    <ul>
                                                                        <li><i class="icon_list"></i> <strong>


                                                                            {item.priced == 0 ? (
                                                                                "FREE FUN"
                                                                            ) : "TICKETS START"}

                                                                        </strong></li>
                                                                        <li><div class="score"><strong>UGX{item.priced}</strong></div></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        )
                                                    ) :
                                                    (
                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Bars
                                                                height="70"
                                                                width="100"
                                                                color="#F21313"
                                                                ariaLabel="bars-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    )



                                                }

                                            </div>

                                            {/* <!-- /row --> */}
                                        </div>
                                        {/* <!-- /isotope-wrapper --> */}
                                    </div>
                                    {/* <!-- /col --> */}
                                </div>
                                {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /container --> */}
                            <div class="bg_color_1">
                                <div class="container margin_60_35">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <a href="#0" class="boxed_list">
                                                <i class="pe-7s-help2"></i>
                                                <h4>Need Help? Contact us</h4>
                                                <p>Contact our customer service.</p>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="#0" class="boxed_list">
                                                <i class="pe-7s-wallet"></i>
                                                <h4>Payments and Refunds</h4>
                                                <p>View our payment policy.</p>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="#0" class="boxed_list">
                                                <i class="pe-7s-note2"></i>
                                                <h4>Quality Standards</h4>
                                                <p>View our product guidelines.</p>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <!-- /row --> */}
                                </div>
                                {/* <!-- /container --> */}
                            </div>
                            {/* <!-- /bg_color_1 --> */}
                        </main>


                        {/* <!-- /main --> */}

                        <Footer />
                        {/* <!--/footer--> */}
                    </div>
                    {/* <!-- page --> */}

                </body>
            </>
        )
    }
}
export default HappeingSoon