import React, { Component, useEffect, useState, CSSProperties } from 'react'
import functions from '../utils/functions'
import { useHistory } from 'react-router-dom';
import { SpinnerCircularSplit } from 'spinners-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ajax from '../utils/ajax';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


const AvailableTics = (props) => {

    const fees = 1000
    const [userId, setUserId] = useState(functions.sessionGuard())
    const [ticketQuantity, setTicketQuantity] = useState(0)
    const [tickeTotal, setTicketTotal] = useState(0)
    const [handlingFee, setHandlingFee] = useState(0)
    const [stepOne, setStepOne] = useState(true)
    const [stepTwo, setStepTwo] = useState(false)
    const [stepThree, setStepThree] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [fullnamed, setFullnamed] = useState("")
    const [phonenumbered, setPhonenumbered] = useState("")
    const [emailed, setEmailed] = useState("")
    const [paymentPhone, setPaymentPhone] = useState("")
    const [cart, setCart] = useState([])
    const [cartDup, setCartDup] = useState([])
    const [subTotal, setSubTotal] = useState(0)
    const [priceId, setPriceId] = useState("")
    const [details, setDetails] = useState(0)
    const [detailss, setDetailss] = useState("")
    const [error, setError] = useState("")
    const [detailsError, setDetailsError] = useState("")
    const [crowdFund, setCrowdFund] = useState("")
    const [eventPrice, setEventPrice] = useState(0)
    const [crownFundPrice, setCrowdFundPrice,] = useState("")
    const [crowdFundId, setCrowdFundId] = useState("")

    //
    const [valueD, setValueD] = useState()


    const nav = useHistory();

    const addToCart = () => {
        if (props.ticketTypes.length > 0) {
            setCart(props.ticketTypes)
            const dup = props.ticketTypes
            setCartDup(dup)
            //console.log("jumiaUgandade",props.tourTypeId)
            setEventPrice(props.priceList[0].price.total_c)
            setCrowdFund(props.tourTypeId)
        }
    }

    const handleTicketIncrease = (idd) => {

        const yup = cart.map((item) =>
            idd === item.priceId ? { ...item, quantity: parseInt(item.quantity) + (parseInt(item.quantity) < 1 ? 1 : 0) } : item
        )
        setCart(yup)
        setPriceId(idd)

    };

    const handleTicketDecrease = (idd) => {
        const yup = cart.map((item) =>
            idd === item.priceId ? { ...item, quantity: parseInt(item.quantity) - (parseInt(item.quantity) > 0 ? 1 : 0) } : item
        )
        setCart(yup)
        setPriceId(idd)
    }

    const handCrowdFundChange = (idd) => {

        if (crownFundPrice.length > 0) {

            const yup = cart.map((item) =>
                idd === item.priceId ? { ...item, quantity: crownFundPrice.length == 1 ? 0 : parseInt(1) , subTotal: crownFundPrice.length == 1 ? 0 : crownFundPrice, amount: crownFundPrice.length == 1 ? 0 : crownFundPrice } : item
            )

            setCart(yup)
            setPriceId(idd)
            setSubTotal(crownFundPrice.length == 1 ? 0 : crownFundPrice)

            //console.log("jimmyJonesk",crownFundPrice.length == 1 ? 0 : crownFundPrice)



        }
    }

    const handleSubTotal = (idd) => { //handlingFee
        const sup = cart.map((item) =>
            idd === item.priceId ? ({ ...item, subtotal: parseInt(item.amount) * (item.quantity) }) : item
        )
        setCart(sup)
    }

    const handling = () => {
        let handle = 0
        cart.map((item) =>
            item.amount === 0 ? (handle = parseInt(0)) : handle += (parseInt(fees) * item.quantity),
        )
        setHandlingFee(handle)
    }

    const notify = (msg) => toast(msg);

    const closeMe = () => {

        //console.log(props.ticketTypes)

        setTicketQuantity(0)
        setTicketTotal(0)
        setHandlingFee(0)
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
        setProcessing(false)
        setFullnamed("")
        setPhonenumbered("")
        setEmailed("")
        setPaymentPhone("")
        setCart([])
        setCart(cartDup)
        // setCrowdFund(false)
        setEventPrice(0)
        setCrowdFundPrice("")

    }

    const doStepOne = () => {
        setStepOne(true)
        setStepTwo(false)
        setStepThree(false)
    }

    const doStepTwo = () => {
        if (ticketQuantity > 0) {
            setStepTwo(true)
            setStepOne(false)
            setStepThree(false)
            //console.log(cart)
            cart.filter(person => person.quantity !== "0");

        } else {
            notify("You must at least buy one ticket")
        }

    }

    const doStepThree = () => {

        if (cart.filter(p => p.fullname).length === cart.filter(p => p.quantity).length && cart.filter(p => p.phoneNumber).length === cart.filter(p => p.quantity).length && cart.filter(p => p.emailAdd).length === cart.filter(p => p.quantity).length) {
            setStepThree(true)
            setStepTwo(false)
            setStepOne(false)
        } else {
            notify("All ticket holder details are required")
        }

    }

    const handleProcessPayment = () => {
        if (paymentPhone != "" && paymentPhon.length != 10) {
            setProcessing(true)
        } else {
            notify("Please Enter A Valid Phone Number")
        }
    }

    const paymentPhon = (e) => {
        if (e.target.value != "") {
            setPaymentPhone(e.target.value)
        } else {
            setPaymentPhone('')
        }
    }

    const fullname = (e, add) => {

        const yup = cart.map((item) =>
            add === item.priceId ? { ...item, fullname: e.target.value != "" ? e.target.value : "" } : item
        )
        setCart(yup)
    }

    const phoneNumber = (e, add) => {

        const yup = cart.map((item) =>
            add === item.priceId ? { ...item, phoneNumber: e.target.value != "" ? e.target.value : "" } : item
        )
        setCart(yup)
    }

    const emailAddress = (e, add) => {

        const yup = cart.map((item) =>
            add === item.priceId ? { ...item, emailAdd: e.target.value != "" ? e.target.value : "" } : item
        )
        setCart(yup)
    }

    const handlePrice = () => {
        let total = 0
        cart.map((item) =>

            total += ((parseInt(item.amount) * item.quantity)),

        )
        setTicketTotal(total + handlingFee)
        setSubTotal(total)
    }

    const handleQuantity = () => {
        let qty = 0
        cart.map((item) =>
            qty += parseInt(item.quantity),
        )
        setTicketQuantity(qty)
    }

    useEffect(() => {
        if (props.ticketTypes) {
            addToCart()
            handlePrice()
            handleQuantity()
            handling()
        }
        if (priceId != "") {
            handleSubTotal(priceId)
        }

        if (crowdFundId != "") {
            handCrowdFundChange(crowdFundId)
        }

    }, [crownFundPrice, cart, props.ticketTypes]) //

    var removeByAttr = function (arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {

                arr.splice(i, 1);

            }
        }
        return arr;
    }

    const onBuyTicket = async (event) => {
        event.preventDefault()
        // handleProcessPayment()
        setProcessing(true)
        setDetailsError("")


        const server_response = await ajax.createTicket(valueD, removeByAttr(cart, 'quantity', 0), tickeTotal, props.tourId);

        if (server_response.status === "OK") {
            //setSearchList(server_response.details.content)
            //console.log("kalos t", server_response.details.message)
            setError(server_response.details.message)

            setTimeout(
                () => {
                    toast.success('🦄 Please check your email for your tickets!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                },
                3000
            );

            setTimeout(
                () => {
                    window.location.reload()
                },
                6000
            );


        } else {
            setError(server_response.details.message)
            if (server_response.details.message.includes("ticket holder")) {
                setStepTwo(true)
                setStepOne(false)
                setStepThree(false)
                setProcessing(false)
                setDetailsError(<span style={{ color: 'red' }}>are invalid</span>)
            }
        }
    }

    return (
        <>

            <div class="modal fade" id="exampleModalToggle" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalToggleLabel" tabindex="-1">

                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />

                            <h3 style={{ marginLeft: '10px' }} class="modal-title fs-5" id="exampleModalToggleLabel">
                                {stepOne ? (
                                    "Available ticket type(s) / categories"
                                ) : null}

                                {stepTwo ? (
                                    <>Ticket Details {detailsError}</>
                                ) : null}

                                {stepThree ? (
                                    <>
                                        Payment {error}
                                    </>
                                ) : null}

                            </h3>
                            {processing ? (
                                <button type="button" class="btn-close" aria-label="Close"></button>
                            ) : (
                                <button type="button" class="btn-close" data-bs-dismiss="modal" onClick={closeMe} aria-label="Close"></button>
                            )}

                        </div>
                        <div class="modal-body">

                            <div class="container">

                                <form method="post" onSubmit={onBuyTicket}>

                                    <div class="row">


                                        {stepOne ? (
                                            <div class="col-xl-4" id='smallphone'>
                                                <div class="mt-5 mt-lg-0">
                                                    <div class="card border shadow-none">
                                                        <div class="card-header bg-transparent border-bottom py-3 px-4">
                                                            <h5 class="font-size-16 mb-0"><strong> {props.tourName}</strong><span class="float-end"></span></h5>
                                                        </div>
                                                        <div class="card-body p-4 pt-2">

                                                            <div class="table-responsive">
                                                                <table class="table mb-0">
                                                                    <tbody>
                                                                        <tr class="bg-light">
                                                                            <th>Grand Total:</th>
                                                                            <td class="text-end">
                                                                                <span class="fw-bold">
                                                                                    UGX {tickeTotal}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}


                                        <div class="col-xl-8">


                                            {stepOne ? (
                                                <>

                                                    {Array.isArray(cart) ?
                                                        (
                                                            cart.map((item, key) =>


                                                                <div class="card border shadow-none" key={key}> {/*onChange={() => addToCart(item)}*/}
                                                                    <div class="card-body">

                                                                        {/* /{setEventPrice(item.amount) } */}

                                                                        <div class="d-flex align-items-start border-bottom pb-3" >
                                                                            <div class="me-4">
                                                                                <img src={props.image} alt="" class="avatar-lg rounded" />
                                                                            </div>
                                                                            <div class="flex-grow-1 align-self-center overflow-hidden">
                                                                                <div>
                                                                                    <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">{item.category} </a></h5>
                                                                                    <p class="text-muted mb-0">
                                                                                        <i class="bx bxs-star text-warning"></i>
                                                                                        <i class="bx bxs-star text-warning"></i>
                                                                                        <i class="bx bxs-star text-warning"></i>
                                                                                    </p>
                                                                                    <p class="mb-0 mt-1">Sales Close  <span class="fw-medium">{props.close}</span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="flex-shrink-0 ms-2">
                                                                                <ul class="list-inline mb-0 font-size-16">
                                                                                    <li class="list-inline-item">
                                                                                        <a href="#" class="text-muted px-1">
                                                                                            <i class="mdi mdi-trash-can-outline"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li class="list-inline-item">
                                                                                        <a href="#" class="text-muted px-1">
                                                                                            <i class="mdi mdi-heart-outline"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                        {crowdFund == "12" ? (<>

                                                                            <div>
                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Goal / Target</p>
                                                                                            <h5 class="mb-0 mt-2"><span class="font-size-16 fw-normal"><strong>UGX {eventPrice} </strong></span></h5>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-6">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Amount to pay [no commas]</p>
                                                                                            <div class="d-inline-flex">
                                                                                                <div class="input-group">
                                                                                                    {/* <input style={{ color: 'black', height: '30px' }} value={crownFundPrice} onChange={e => {setCrowdFundPrice(e.target.value.replace(/\D/,''));handCrowdFundChange(item.priceId)}} /> */}
                                                                                                    <input style={{ color: 'black', height: '30px' }} value={crownFundPrice} onChange={e => { setCrowdFundPrice(e.target.value.replace(/\D/, '')); setCrowdFundId(item.priceId) }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div class="col-md-3">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Total</p>
                                                                                            <h5>UGX {item.subtotal}</h5>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                        )
                                                                            : (

                                                                                <>

                                                                                    <div>
                                                                                        <div class="row">
                                                                                            <div class="col-md-4">
                                                                                                <div class="mt-3">
                                                                                                    <p class="text-muted mb-2">Price</p>
                                                                                                    <h5 class="mb-0 mt-2"><span class="font-size-16 fw-normal"><strong>UGX {item.amount}</strong></span></h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-5">
                                                                                                <div class="mt-3">
                                                                                                    <p class="text-muted mb-2">Quantity</p>
                                                                                                    <div class="d-inline-flex">
                                                                                                        <div class="input-group">
                                                                                                            <input style={{ color: 'black' }} type="button" value="-" class="btn btn-success" data-field="quantity" onClick={() => handleTicketDecrease(item.priceId)} />
                                                                                                            <input style={{ color: 'black' }} type="button" value={item.quantity} class="btn btn-success" />
                                                                                                            <input style={{ color: 'black' }} type="button" value="+" class="btn btn-success" onClick={() => handleTicketIncrease(item.priceId)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-3">
                                                                                                <div class="mt-3">
                                                                                                    <p class="text-muted mb-2">Total</p>
                                                                                                    <h5>UGX {item.subtotal}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </>

                                                                            )}

                                                                    </div>
                                                                </div>

                                                            )
                                                        ) :
                                                        (
                                                            null
                                                        )

                                                    }

                                                </>
                                            ) : null}


                                            {stepTwo ? (



                                                Array.isArray(cart) ?
                                                    (
                                                        cart.map((item, key) =>
                                                            <>

                                                                {parseInt(item.quantity) > 0 ? (



                                                                    <div class="card border shadow-none" key={key}>
                                                                        <div class="card-body">

                                                                            <div class="d-flex align-items-start border-bottom pb-3">
                                                                                <div class="flex-grow-1 align-self-center overflow-hidden">
                                                                                    <div>
                                                                                        <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">Ticket Holder [ {item.category}] </a></h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="flex-shrink-0 ms-2">
                                                                                    <ul class="list-inline mb-0 font-size-16">
                                                                                        <li class="list-inline-item">
                                                                                            <a href="#" class="text-muted px-1">
                                                                                                <i class="mdi mdi-trash-can-outline"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="list-inline-item">
                                                                                            <a href="#" class="text-muted px-1">
                                                                                                <i class="mdi mdi-heart-outline"></i>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <div class="row">
                                                                                    <div class="col-md-4">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Fullname {detailss}</p>
                                                                                            <input type='text' placeholder='eg john doe' onChange={(e) => fullname(e, item.priceId)} value={item.fullname} class="form-control" required />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Phone number</p>
                                                                                            <input onChange={(e) => phoneNumber(e, item.priceId)} placeholder='eg 0701123456' class="form-control" value={item.phoneNumber} type="text" required />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-4">
                                                                                        <div class="mt-3">
                                                                                            <p class="text-muted mb-2">Email Address</p>
                                                                                            <input onChange={(e) => emailAddress(e, item.priceId)} placeholder='eg johndoe@gmail.com' value={item.emailAdd} class="form-control" type="text" required />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                ) : (null)}

                                                            </>

                                                        )
                                                    ) :
                                                    (
                                                        null
                                                    )





                                                // <div class="card border shadow-none">
                                                //     <div class="card-body">

                                                //         <div class="d-flex align-items-start border-bottom pb-3">
                                                //             <div class="flex-grow-1 align-self-center overflow-hidden">
                                                //                 <div>
                                                //                     <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">Ticket Holder </a></h5>
                                                //                 </div>
                                                //             </div>
                                                //             <div class="flex-shrink-0 ms-2">
                                                //                 <ul class="list-inline mb-0 font-size-16">
                                                //                     <li class="list-inline-item">
                                                //                         <a href="#" class="text-muted px-1">
                                                //                             <i class="mdi mdi-trash-can-outline"></i>
                                                //                         </a>
                                                //                     </li>
                                                //                     <li class="list-inline-item">
                                                //                         <a href="#" class="text-muted px-1">
                                                //                             <i class="mdi mdi-heart-outline"></i>
                                                //                         </a>
                                                //                     </li>
                                                //                 </ul>
                                                //             </div>
                                                //         </div>

                                                //         <div>
                                                //             <div class="row">
                                                //                 <div class="col-md-4">
                                                //                     <div class="mt-3">
                                                //                         <p class="text-muted mb-2">Fullname</p>
                                                //                         <input type='text' onChange={fullname} value={fullnamed} class="form-control" />
                                                //                     </div>
                                                //                 </div>
                                                //                 <div class="col-md-4">
                                                //                     <div class="mt-3">
                                                //                         <p class="text-muted mb-2">Phone number</p>
                                                //                         <input onChange={phoneNumber} value={phonenumbered} class="form-control" type="text" />
                                                //                     </div>
                                                //                 </div>
                                                //                 <div class="col-md-4">
                                                //                     <div class="mt-3">
                                                //                         <p class="text-muted mb-2">Email Address</p>
                                                //                         <input onChange={emailAddress} value={emailed} class="form-control" type="text" />
                                                //                     </div>
                                                //                 </div>
                                                //             </div>
                                                //         </div>

                                                //     </div>
                                                // </div>

                                            ) : null}

                                            {stepThree ? (
                                                <div class="card border shadow-none">
                                                    <div class="card-body">

                                                        <div class="d-flex align-items-start border-bottom pb-3">
                                                            <div class="flex-grow-1 align-self-center overflow-hidden">
                                                                <div>
                                                                    <h5 class="text-truncate font-size-18"><a href="#" class="text-dark">
                                                                        {processing ? (
                                                                            <>
                                                                                <strong>
                                                                                    Processing . . . <br />
                                                                                </strong>

                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Payment Details
                                                                            </>
                                                                        )}

                                                                    </a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div class="flex-shrink-0 ms-2">
                                                                <ul class="list-inline mb-0 font-size-16">
                                                                    <li class="list-inline-item">
                                                                        <a href="#" class="text-muted px-1">
                                                                            <i class="mdi mdi-trash-can-outline"></i>
                                                                        </a>
                                                                    </li>
                                                                    <li class="list-inline-item">
                                                                        <a href="#" class="text-muted px-1">
                                                                            <i class="mdi mdi-heart-outline"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="col-md-4 col-lg-12" style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                                        <img class="btn mt-2 mr-2" src="../assets/img/airtel.webp"
                                                                            style={{ height: '60px', width: '100%', border: 'solid', borderColor: 'red', marginRight: '10px', marginTop: '20px' }} alt="Airtel Mm Logo" />
                                                                        <img class="btn mt-2 mr-2" src="../assets/img/mtn.png"
                                                                            style={{ height: '60px', width: '100%', border: 'solid', borderColor: 'yellow' }} alt="Mtn Mm Logo" />
                                                                    </div>
                                                                    <div class="mt-3">
                                                                        <p class="text-muted mb-2">Payment Phone:</p>
                                                                        {/* <input onChange={paymentPhon} value={paymentPhone} placeholder='eg 0701123456' class="form-control" type="text" readOnly={processing} /> */}

                                                                        <div style={{ border: 'solid', borderColor: 'wheat', marginLeft: '5px' }}>
                                                                            <PhoneInput
                                                                                // international
                                                                                countryCallingCodeEditable={false}
                                                                                value={valueD}
                                                                                onChange={setValueD}
                                                                                countries={['UG']}
                                                                                defaultCountry="UG" />
                                                                        </div>

                                                                        <span style={{ color: 'red' }}>{valueD && (formatPhoneNumber(valueD).length === 11) ? '' : "invalid phone number"}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            ) : null}

                                            {/* <!-- end card --> */}

                                            <div class="row my-4">
                                                <div class="col-sm-6">

                                                    {processing ? (
                                                        null
                                                    ) : (
                                                        <a class="btn btn-warning" onClick={closeMe} data-bs-dismiss="modal">
                                                            <i class="mdi mdi-cart-outline me-1"></i> close </a>
                                                    )}
                                                </div>
                                                {/* <!-- end col --> */}
                                                <div class="col-sm-6">
                                                    <div class="text-sm-end mt-2 mt-sm-0">

                                                        {stepOne ? (
                                                            <a onClick={doStepTwo} class="btn btn-success">
                                                                Proceed </a>
                                                        ) : (<>
                                                            {stepThree ? (<>
                                                                {processing ? (
                                                                    <span class="btn btn-success" >
                                                                        <SpinnerCircularSplit thickness={120} enabled={processing} color='#FFFFFF' height='30' />
                                                                    </span>
                                                                ) : (
                                                                    <button id='processPay' disabled={valueD && (formatPhoneNumber(valueD).length === 11) ? false : true} class="btn btn-success" type='submit'>
                                                                        Process Payment </button>
                                                                )}




                                                            </>
                                                            ) : (<>
                                                                <a onClick={doStepOne} class="btn btn-danger">
                                                                    Back to start </a>

                                                                <button onClick={doStepThree} class="btn btn-success" style={{ marginLeft: '15px' }}>
                                                                    Proceed </button>
                                                            </>
                                                            )}
                                                        </>
                                                        )}

                                                        {/* <a onClick={doStepTwo} class="btn btn-success">
                                                    <i class="mdi mdi-cart-outline me-1"></i> Proceed </a> */}
                                                    </div>
                                                </div>
                                                {/* <!-- end col --> */}
                                            </div>
                                            {/* <!-- end row--> */}
                                        </div>

                                        <div class="col-xl-4" id='smallphonetwo'>
                                            <div class="mt-5 mt-lg-0">
                                                <div class="card border shadow-none">
                                                    <div class="card-header bg-transparent border-bottom py-3 px-4">
                                                        <h5 class="font-size-16 mb-0"><strong> {props.tourName}</strong><span class="float-end"></span></h5>
                                                    </div>
                                                    <div class="card-body p-4 pt-2">

                                                        <div class="table-responsive">
                                                            <table class="table mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Sub Total :</td>
                                                                        <td class="text-end"> UGX {subTotal}{/*{isNaN(tickeTotal) ? 0 : tickeTotal} */}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Discount : </td>
                                                                        <td class="text-end"> UGX  0</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Shipping Charge :</td>
                                                                        <td class="text-end"> UGX 0</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Handling Fee : </td>
                                                                        <td class="text-end">UGX {isNaN(handlingFee) ? 0 : handlingFee}</td>
                                                                    </tr>
                                                                    <tr class="bg-light">
                                                                        <th>Total :</th>
                                                                        <td class="text-end">
                                                                            <span class="fw-bold">
                                                                                UGX {tickeTotal}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/* <!-- end table-responsive --> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                                {/* <!-- end row --> */}

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AvailableTics
